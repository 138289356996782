import React, { ReactElement } from "react";
import BottomImage from "../bottomImage";

import styles from "./style.module.scss";

export default function DoYouKnow(): ReactElement {
    return (
        <div
            className={`flex flex-col md:flex-row flex-nowrap items-center justify-between mb-12 ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full md:w-3/5">
                <div
                    className={` font-bold mb-8 capitalize  ${styles["title"]}`}
                >
                    Reducing Patient No-Shows and Improving Follow-Up Care
                </div>
                <p className={` text-base lg:text-lg ${styles["brief"]}`}>
                    Medical software encompasses a range of tools designed for
                    use in healthcare settings. These tools gather and analyze
                    patient health data for various purposes, including future
                    reference, long-term behavioral studies, and immediate
                    diagnoses. This software is widely utilized across the
                    healthcare sector, benefiting pharmacies, laboratories,
                    mental health centers, and physical rehabilitation
                    facilities.
                </p>
            </div>
            <BottomImage />
        </div>
    );
}
