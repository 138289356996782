import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function BottomImage(): ReactElement {
    return (
        <div className={`w-full md:w-3/5 ${styles["mainBannerWrapper"]}`}>
            <Image
                src="/medicalsoftwarequotes/assets/images/bottomImgOpt.png"
                className={styles["background-img"]}
                alt="Medical Software - Minimized Patient No-Shows and Enhanced Follow-Up Care"
                layout="intrinsic"
                priority
                width={634}
                height={550}
            />
        </div>
    );
}
